import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Wrapper></Wrapper>
    </Layout>
  );
};

export default IndexPage;
